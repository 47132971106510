<template>
  <div class="ml-5 mr-5">
    <div class="text-right">
      <loading :active.sync="isLoading" :can-cancel="false" loader="dots" color="#007bff" :height="45" :width="75"
        :is-full-page="fullPage"></loading>
    </div>
    <!-- v-data-table  -->
    <div>
      <v-dialog v-model="confirm_submit" persistent max-width="350">
        <v-card>
          <span id="spanheading" >Are you sure you?</span>
          <div v-if="choosenaffinitiestomerge.length">
            <v-card-title class="text-start text-h4 pt-1">
              Affinities to be merged
            </v-card-title>
            <v-card-text>
              <ul class="mx-5">
                <li v-for="(a, i) in choosenaffinitiestomerge" v-bind:key="i">
                  {{ a.name }} - {{ a.product_affinity_count }}
                </li>
              </ul>
            </v-card-text>
          </div>
          <div v-if="affinity_merge_with.length">
            <v-card-title class="text-start text-h4  pt-1">
              Affinity to merge with
            </v-card-title>
            <v-card-text>
              <ul class="mx-5">
                <li>
                  {{ affinity_merge_with[0].affinity_name }} -
                  {{ affinity_merge_with[0].product_affinity_count }}
                </li>
              </ul>
            </v-card-text>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="product" small class="mt-5" @click="ToBeMerged">Submit</v-btn>
            <v-btn color="red lighten-1" small class="mt-5" @click="confirm_submit = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container fluid>
        <v-row>
          <v-col v-show="checkIfOperationExistForModule('edit')" lg="3" md="3">
            <div id="searchForm__autocomplete">
              <div v-for="c in choosenaffinitiestomerge" v-bind:key="c.id" id="searchForm__autocomplete--chip">
                {{ c.name }}
                <v-icon dark left id="searchForm__autocomplete--icon" @click="removeaffinity(c)">
                  mdi-close-circle
                </v-icon>
              </div>
              <!-- <div id="searchForm__autocomplete--chip" v-if="affinity.length > 0">
          <v-chip v-for="c in choosenaffinitiestomerge" :key="c.id" close @click:close="removeaffinity(c)">
            {{ c.name }}
            <v-icon small>mdi-close</v-icon>
          </v-chip>
        </div> -->
            </div>
              <div  id="searchForm__autocomplete--text_field">
                <v-text-field
                 type="text"  autofocus
                  label="Affinities to be merged" v-model="affinity"
                  :loading="loading"
                  max-width="100%"> 
                </v-text-field>
            </div>
            <div>
              <ul id="searchForm__list">
                <li v-for="c in affinities" v-bind:key="c.id" @click="chooseaffinity(c)">
                  {{ c.name }}
                </li>
              </ul>
            </div>
          </v-col>
          <v-col v-show="checkIfOperationExistForModule('edit')" lg="3" md="3">
            <v-autocomplete v-model="affinityToBeMergedWith" :loading="comploder" :search-input.sync="MergeaffinitySearch"
              :items="Mergeaffinities" :no-data-text="''" label="Merge with Affinity"  clearable chips small-chips>
            </v-autocomplete>
          </v-col>
          <v-col lg="3" md="3">
            <v-btn v-show="checkIfOperationExistForModule('edit')" small color="primary" class="mt-5" @click="ConfirmSubmitDialoge()">
              Merge
            </v-btn>
            <v-btn v-show="checkIfOperationExistForModule('view')" small color="grey" class="mt-5" @click="reset()">
              Reset
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
        </v-row>
        <v-row v-show="checkIfOperationExistForModule('view')">
          <v-col cols="12">
            <div class="tableWrapper">
              <v-data-table v-model="selected" :headers="headers" item-key="id" :page.sync="page" :sortBy.sync="sortby"
                :sort-desc.sync="sortDesc" :items="Row_data" :options.sync="options" :server-items-length="totRecords"
                :loading="isLoading" class="elevation-1" hide-default-footer @page-count="pageCount = $event">
                <template v-slot:top>        
                  <div class="pb-2"  style=" position: sticky;"  >
                        <v-toolbar-title style="left: 40%;position: relative;">Merged affinities Logs</v-toolbar-title>                        
              </div>
                </template>
                <template v-slot:item.view_logs="{ item }">
                  <v-icon dense class="eyebtn" @click="makeExtendedData(item)">
                    mdi-eye
                  </v-icon>
                </template>
                <template v-slot:header>
                  <v-dialog v-model="extendedDialogue" persistent width="40%">
                    <ExtendedData :extended_data="extended_data" :title="title" :headers="Extendedheaders" @clsdlg="clsdlg">
                    </ExtendedData>
                  </v-dialog>
                </template>
              </v-data-table>
              <div class="text-center pt-4 pb-4">
                <Pagination :isloading="isLoading" :startRecord="startRecord" :currentPage="currentPage"
                  :lastPage="lastPage" :lastRecord="lastRecord" :totRecords="totRecords"
                  :isCurrentPageClass="isCurrentPageClass" :perpage="perpage" :getLastPageClass="getLastPageClass"
                  :totPage="totPage" :getPages="getPages" @handlePerPage="handlePerPage" @paginate="paginate" @last="last"
                  @getDataByPage="getDataByPage" :showPerPage="showPerPage" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- v-data-table-ends -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Pagination from "./component/Pagination.vue";
import ExtendedData from "./component/ExtendedData.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading, Pagination, ExtendedData },
  data() {
    return {
      title:"Detail Log",
      affinity: "",
      selected: "",
      options: {},
      Row_data: [],
      affinitySearch: "",
      MergeaffinitySearch: "",
      affinities: [],
      affinityToMerged: null,
      affinityToBeMergedWith: null,
      panType: "",
      show_zero_product_count: "no",
      viewmodexist: "",
      viewIfOperationExistForModule: "",
      mergingaffinity: "",
      isLoad: true,
      updialogue: false,
      sortby: "",
      perpage: 30,
      totRecords: null,
      page: 1,
      isLoading: false,
      fullPage: true,
      showPerPage: true,
      sortDesc: false,
      headers: [
        {
          text: "Merged affinities",
          value: "to_merge_affinity_name",
          sortable: false,
          width: "140",
          class: "v-data-table-header",
        },
        {
          text: "Merged with",
          value: "merge_with_affinityname",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "User",
          value: "user_id",
          sortable: false,
          width: "100",
          class: "v-data-table-header",
        },
        {
          text: "Date",
          value: "added_at",
          sortable: false,
          width: "160",
          class: "v-data-table-header",
        },
        {
          text: "Status",
          value: "status_summary",
          sortable: false,
          width: "100",
          class: "v-data-table-header",
        },
        {
          text: "View Log",
          value: "view_logs",
          sortable: false,
          width: "100",
          class: "v-data-table-header",
        },
      ],
      Extendedheaders: [
        {
          text: "V1 Status",
          value: "db_entries_merged_v1",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "V2 Status",
          value: "db_entries_merged_v2",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Power Search Status",
          value: "powersearch_update_scheduled",
          sortable: false,
          class: "v-data-table-header",
        }

      ],
      debounce: null,
      ToMergeaffinities_data: [],
      WithMergeaffinities_data: [],
      Mergeaffinities: [],
      extended_data: [],
      extendedDialogue: false,
      choosenaffinitiestomerge: [],
      affinities: [],
      confirm_submit: false,
      affinity_merge_with: [],
      comploder: false,
      loading: false

    };
    ////// table data ends here
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////

  },
  mounted() {
    var view = this;
    view.getData();

  },
  methods: {
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    getDataByPage(value) {
      this.page = value;
      this.getData();
    },
    handlePerPage(value) {
      this.perpage = value;
      this.getData();
    },
    paginate(n) {
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getData();
    },
    last(value) {
      this.page = this.lastPage;
      this.getData();
    },
    getaffinity(query) {
      let view = this;
      let affinities = [];
      let affinities_data = [];
      const path = view.$url("AFFINITY_SEARCH");
      //view.isLoading = true;
   
      let searchData = {
        "q": query.trim(),
      }     
      view
        .$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .post(path, searchData)
        .then((res) =>{
          return res.payload
        }).then((res)=>{
          //view.isLoading = false;
          let affinity =res.data.data[0];
          if (Array.isArray(affinity) && affinity.length) {
            let i;
            for (i = 0; i < affinity.length; i++) {
              let tempobj = {
                affinity_id: affinity[i][0],
                affinity_name: affinity[i][1],
                product_affinity_count: affinity[i][4],
              };
              affinities_data.push(tempobj);
              affinities.push(affinity[i][1]);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          return { "affinities": affinities, "affinities_data": affinities_data }
        });
      return { "affinities": affinities, "affinities_data": affinities_data }
    },
    /////
    reset() {
      this.page = 1;
      this.perpage = 30;
      this.affinities = [];
      this.Mergeaffinities = [];
      this.getData();
    },
    getData() {
      var view = this;
      view.isLoading = true;
      const path = view.$url("MERGE_BASE_URL") + "mergelogdata/affinity";
      var data = {}
      data["page_no"] = view.page,
        data["per_page"] = view.perpage
      view.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .post(path, data).then((res) => {
          view.isLoading = false;
          view.totRecords = res.data.total_records;
          view.Row_data = res.data.data

        }).catch((error) => {
          console.error(error);
          view.isLoading = false;
        });

    },
    ToBeMerged() {
      var view = this;
      // var to_merge_comp_arr = view.affinityToMerged.map(function (mcomp) {
      //     for (let index = 0; index < view.ToMergeaffinities_data.length; index++) {
      //       if (view.ToMergeaffinities_data[index].affinity_name === mcomp) {
      //             return view.ToMergeaffinities_data[index].affinity_id;
      //         }
      //     }
      // });

      var to_merge_comp_arr = view.choosenaffinitiestomerge.map(item => item.id);
      var merg_comp_obj = view.WithMergeaffinities_data.filter(obj => {
        return obj.affinity_name === view.affinityToBeMergedWith
      });

      const path = view.$url("MERGE_BASE_URL") + "mergeaffinity/";
      view.isLoading = true;
      let data = {};
      data["to_merge"] = to_merge_comp_arr;
      data["merge_with"] = merg_comp_obj.length ? merg_comp_obj[0].affinity_id : 0;
      data["user_id"] = view.$utils.getCookies('username');
      // if (data["merge_with"] && data["to_merge"].length && data["user_id"]) {
      //   console.log(data["to_merge"].includes(data["merge_with"]));
      if (!data["to_merge"].includes(data["merge_with"])) {
        view
          .$fetch({ requiresAuth: true, operation: "view", vueScope: view })
          .post(path, data)
          .then((res) => {
            view.isLoading = false;
            view.confirm_submit = false;
            view.$swal.fire({
              icon: "success",
              title: "Affinities Merged",
              text: "Affinities have been merged successfully.",

            });
            view.affinities = [];
            view.choosenaffinitiestomerge = [];
            view.Mergeaffinities = [];
            to_merge_comp_arr = [];
            merg_comp_obj = []
  
            view.getData();

          }).catch((error) => {
            console.error(error);
            view.isLoading = false;
            view.$swal.fire({
              position: "bottom-end",
              icon: "error",
              title: "Error in mergeig the affinities.",
              showConfirmButton: false,
              timer: 2500,
            });

          });
      }
      else {
        view.isLoading = false;
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "A affinity cannot merge into itself.",
          showConfirmButton: false,
          timer: 2500,
        });

      }
      // }
      // else {
      //     view.isLoading = false;   
      //        view.$swal.fire({
      //     position: "bottom-end",
      //     icon: "error",
      //     title: "Fields can't be empty.",
      //     showConfirmButton: false,
      //     timer: 2500,
      //   });
      // };

    },

    ConfirmSubmitDialoge() {
      var view = this;
      view.affinity_merge_with = view.WithMergeaffinities_data.filter(obj => {
        return obj.affinity_name === view.affinityToBeMergedWith
      });

      if (view.affinity_merge_with.length && view.choosenaffinitiestomerge.length) {
        view.confirm_submit = true;

      }
      else {
        view.isLoading = false;
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Fields can't be empty.",
          showConfirmButton: false,
          timer: 2500,
        });
      };

    },

    makeExtendedData(item) {
      this.extended_data = [];
      this.extended_data.push(item);
      this.extendedDialogue = true
    },
    clsdlg() {
      this.extendedDialogue = false;
    },
    chooseaffinity: function (c) {
      var view = this,
        is_affinity = view.choosenaffinitiestomerge.filter((o) => c.id === o.id);
      if (Array.isArray(is_affinity)) {
        if (is_affinity.length === 0) {
          view.choosenaffinitiestomerge.push(c);
          view.affinities = view.affinities.filter((o) => c.id !== o.id);
          // view.$emit("addaffinity", c.id);
        }
      }
      // console.log(view.choosenaffinitiestomerge);
      this.clickedOut();
    },
    clickedOut: function () {
      this.affinities = [];
      this.affinity = "";
    },
    removeaffinity: function (c) {
      var view = this;
      view.choosenaffinitiestomerge = view.choosenaffinitiestomerge.filter(
        (o) => c.id !== o.id
      );
      if (view.affinities.length > 0) {
        view.affinities.unshift(c);
      }
      // view.$emit("removeaffinity", c.id);
    },
  },
  watch: {
    affinity: function (o) {
      var view = this;
      // view.comploder = false;
    
      if (o !== null && o.length > 2) {
        view.loading = true;
        view.affinities = [];
        this.message = null;
        this.typing = "You are typing";
        const path = view.$url("AFFINITY_SEARCH"); + "?q=" + o.toString()
          + "&page=" + this.page + "&show_zero_product_count=" + this.show_zero_product_count;
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.typing = null;
          this.message = o;          
          let searchdata = {};
          searchdata["q"] = o.trim();
          view.$fetch({ requiresAuth: true, operation: "add", vueScope: view, })
            .post(path, searchdata).then(function (res) {
              let data = res.data.payload.data[0];
              console.log(data);
              for (var i = 0; i < data.length; i++) {
                view.affinities.push({
                  id: data[i][0],
                  name: data[i][1],
                  product_affinity_count: data[i][4],
                  // secondary_affinity_count: data[i].secondary_affinity_count
                });
              }
            
              view.loading = false;
            })
            .catch(function (e) {
              console.log(e);
            });
        }, 600);
      } else {
        view.affinities = [];
      }
    },
    MergeaffinitySearch: function (o) {
      var view = this;

      if (o !== null && o.length >= 2) {
        view.comploder = true;
        view.Mergeaffinities = [];
        view.WithMergeaffinities_data = [];
        clearTimeout(view.debounce)
        view.debounce = setTimeout(() => {

      const path = view.$url("AFFINITY_SEARCH");
      //view.isLoading = true;  
      let searchData = {
        "q": o.trim(),
      }     
      view
        .$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .post(path, searchData)
         .then((res)=>{
          //view.isLoading = false;
          view.Mergeaffinities = [];
          view.WithMergeaffinities_data = [];
          let affinity = res.data.payload.data[0];
          if (Array.isArray(affinity) && affinity.length) {
            let i;
            for (i = 0; i < affinity.length; i++) {
              let tempobj = {
                affinity_id: affinity[i][0],
                affinity_name: affinity[i][1],
                product_affinity_count: affinity[i][4],
              };
              view.WithMergeaffinities_data.push(tempobj);
              view.Mergeaffinities.push(affinity[i][1]);
            }
          }
           view.comploder = false;
        })
        .catch((error) => {
          console.error(error);
          view.comploder = false;
          view.WithMergeaffinities_data = []
           view.Mergeaffinities=[]
        })
          // let res = view.getaffinity(o)
          // view.Mergeaffinities = res["affinities"]
          // view.WithMergeaffinities_data = res["affinities_data"]
        }, 500)

      } else {

        view.Mergeaffinities = []

      }

    },

  },


}


</script>

<style scoped lang="sass">
@import './../../sass/_common'



#searchForm
    &__container
        margin: 0!important
        max-width: 100%!important
    &__list_style
        position: absolute
    &__autocomplete
        
        display: grid
        grid-template-columns: auto auto
        &--text_field

            outline: none
        &--text_field:focus
           outline: none
        &--chip
            background: lightgrey
            border-radius: 12px
            padding: 2px 0px 0px 10px
            margin: 0px 2px 2px 0px
            height: -webkit-fit-content
            height: -moz-fit-content
            height: fit-content
            font-size: 12px
            display: flex
            justify-content: center
            align-items: center
        &__icon
            cursor: pointer
    &__list
        list-style: none
        margin: 0
        padding: 0
        position: absolute
        z-index: 1
        width: 300px
        max-height: 300px
        overflow-y: scroll
        li
            background: #fff
            border-bottom: 1px solid #d2d2d2
            padding: 10px
            cursor: pointer
            z-index: 1
    &__hidefilters
        margin: 15px 0 0 0!important
        @include pointer
    &__filters
        color: #8b9bf8
        @include pointer

#searchForm__container div.col
  padding-top: 1px
  padding-bottom: 1px
#searchForm__container div.row
  margin-top: 0px
#searchForm__container
  padding-top: 0px
  .v-input--radio-group.v-input--radio-group--row .v-radio
    margin-right: 0px
 
#spanheading
   display: block
   text-align: center
   font-family:"Times New Roman"!important
   font-size:26px
   font-weight:600px
   Color:#595959

     
</style>


